@import "../../../style/variables/function/color";

.ant {
  &-collapse {
    &-arrow {
      align-items: center;
      background-color: color("primary");
      border-radius: 50%;
      display: flex;
      font-size: 13px;
      height: 17px;
      justify-content: center;
      transform: rotate(90deg);
      transition: 0.2s all ease-in-out;
      width: 17px;
      svg {
        fill: color("light");
      }
    }
    &-content {
      transition: 0.3s all ease-in-out;
      &-active {
        margin-bottom: 28px;
      }
      &-hidden {
        display: none;
      }
    }
    &-expand-icon {
      order: 2;
    }
    &-header {
      align-items: center;
      border-bottom: 1px solid color("primary");
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      padding-bottom: 8px;
      &-text {
        color: color("primary");
        order: 1;
      }
    }
    &-item {
      margin-top: 38px;
      &-active {
        .ant-collapse-arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}
