@import "../../../../style/variables/function/color";
@import "../../../../style/variables/function/font";

.ant {
  &-tabs {
    &-tab {
      &-active {
        .ant {
          &-tabs {
            &-label {
              &-total {
                background-color: color("tabs-active-total");
                border-color: color("tabs-active-total");
                color: color("light");
              }
            }
          }
        }
      }
    }
  }
}
