@import "../../../../style/variables/function/color";
@import "../../../../style/variables/function/font";

.label-required {
  align-items: center;
  display: flex;
  &-content {
    flex-grow: 1;
    font-size: fsize("large");
    font-weight: fweight("medium");
    text-align: right;
  }
  &-text {
    background-color: color("danger");
    border-radius: 4px;
    color: color("light");
    font-size: fsize("small");
    height: 29px;
    margin-left: 10px;
    min-width: 40px;
    padding: 4px;
    text-align: center;
    &-en {
      min-width: 62px;
    }
    &-blue {
      background-color: color("label-blue");
    }
  }
}
