@import "../../../../../../style/variables/function/color";

.ant {
  &-input {
    &-group {
      display: flex;
      width: 100%;
      .ant {
        &-form {
          &-item {
            &-row {
              display: block;
            }
            &-label {
              margin-top: initial !important;
              width: initial !important;
              > label {
                height: initial;
                justify-content: initial;
              }
            }
            &-tooltip {
              text-align: left;
              margin-left: 0;
              &:focus-visible {
                box-shadow: 0px 0px 4px color("focus");
              }
            }
          }
        }
      }
      .form-label {
        flex-grow: initial;
      }
      &-wrapper {
        align-items: center;
        border: 1px solid color("form-entry-border");
        border-radius: 4px;
        display: flex;
        height: 40px;
        padding: 0 11px;
        .ant-input {
          border: none;
          flex-grow: 1;
          height: initial;
          padding-left: 0;
          &:focus {
            box-shadow: none;
          }
          &-group {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
}
