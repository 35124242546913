@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";

.ant {
  &-tabs {
    &-ink-bar {
      display: none;
    }
    &-margin {
      .ant {
        &-tabs {
          &-nav {
            margin-bottom: 28px;
          }
        }
      }
    }
    &-nav {
      &-list {
        display: flex;
        flex-wrap: wrap;
      }
      &-operations {
        display: none;
        &-hidden {
          display: none;
        }
      }
      &-more {
        pointer-events: none;
      }
    }
    &-tab {
      border: 1px solid transparent;
      border-radius: 4px;
      color: color("primary");
      cursor: pointer;
      font-size: fsize("large");
      padding: 4px 8px;
      margin-bottom: 11px;
      &:not(:first-child) {
        margin-left: 4px;
      }
      &-active {
        background-color: color("tabs-active");
        border-color: color("tabs-active-border");
        color: color("text-tabs-active");
        font-weight: fweight("bold");
      }
      &-custom {
        overflow: hidden;
        .ant {
          &-tabs {
            &-nav-list {
              border: 1px solid color("border");
              border-bottom: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              overflow: hidden;
            }
            &-tab {
              border: none;
              border-left: 1px solid color("border");
              border-radius: 0;
              flex-grow: 1;
              height: 40px;
              margin-bottom: 0;
              margin-left: 0;
              padding: 7px 11px;
              overflow: hidden;
              &-active {
                box-shadow: inset 0px 0px 0px 1px color("tabs-active-border");
              }
              &:first-child {
                border-left: none;
                border-top-left-radius: 10px;
              }
              &:nth-last-child(2) {
                border-top-right-radius: 10px;
              }
            }
          }
        }
        &-mobile {
          .ant {
            &-tabs {
              &-tab {
                border: 1px solid color("border");
                font-size: fsize("small");
                margin-right: 6px;
                margin-left: 0;
                margin-bottom: 11px;
                padding: 6px;
                &-active {
                  border-color: color("tabs-active-border");
                }
              }
            }
          }
        }
      }
    }
    &-tabpane {
      opacity: 0;
      visibility: hidden;
      height: 0;
      transition: all 0.3s ease-in-out;
      &-active {
        opacity: 1;
        visibility: visible;
        height: initial;
      }
      &-hidden {
        display: none;
      }
    }
    &-children-none {
      .ant {
        &-tabs {
          &-content-holder {
            display: none;
          }
        }
      }
    }
  }
}
