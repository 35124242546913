@import "../../variables/function/color";
@import "../../variables/function/font";

.ant {
  &-picker {
    align-items: center;
    background-color: color("light");
    border-radius: 4px;
    border: 1px solid color("form-entry-border");
    display: flex;
    height: 40px;
    padding: 0 11px;
    position: relative;
    &-disabled {
      background-color: color("disabled");
      opacity: 0.7;
    }
    &-body {
      padding: 8px;
    }
    &-cell {
      cursor: pointer;
      opacity: 0.5;
      text-align: center;
      &-in-view {
        opacity: 1;
      }
      &-today {
        .ant {
          &-picker {
            &-cell {
              &-inner {
                border-color: color("primary");
              }
            }
          }
        }
      }
      &-selected {
        .ant {
          &-picker {
            &-cell {
              &-inner {
                background-color: color("primary");
                color: color("light");
              }
            }
          }
        }
      }
      &-inner {
        border: 1px solid transparent;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        margin: 0 auto;
      }
      &-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    &-content {
      border-collapse: collapse;
      font-size: fsize("normal");
      th {
        font-weight: fweight("medium");
        height: 30px;
        line-height: 30px;
        width: 32px;
      }
    }
    &-dropdown {
      position: absolute;
      top: -9999px;
      left: -9999px;
      z-index: 1050;
      &-hidden {
        display: none;
      }
      &-range {
        .ant {
          &-picker {
            &-panel {
              border-radius: 0;
              box-shadow: none;
            }
            &-panels {
              border-radius: 4px;
              box-shadow: 0 3px 6px -4px color("box-shadow"),
                0 6px 16px color("box-shadow"),
                0 9px 28px 8px color("box-shadow");
            }
          }
        }
      }
    }
    &-footer {
      border-top: 1px solid color("border");
      text-align: center;
      line-height: 40px;
    }
    &-header {
      border-bottom: 1px solid color("border");
      display: flex;
      height: 40px;
      justify-content: space-between;
      padding-left: 8px;
      padding-right: 8px;
      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
      }
      &-view {
        line-height: 40px;
      }
    }
    &-input {
      align-items: center;
      display: inline-flex;
      flex-grow: 1;
      width: 100%;
      input {
        font-family: "tkk-yu-gothic";
        font-size: fsize("large");
        border: none;
        outline: none;
        padding: 0;
        width: 100%;
      }
    }
    &-next-icon,
    &-super-next-icon,
    &-prev-icon,
    &-super-prev-icon {
      display: inline-block;
      height: 14px;
      position: relative;
      top: 2px;
      transform: rotate(-45deg);
      width: 14px;
      &::after,
      &::before {
        border: 0 solid color("form-entry-border");
        content: "";
        display: inline-block;
        height: 7px;
        position: absolute;
        width: 7px;
      }
      &::before {
        left: 0;
        top: 0;
      }
      &::after {
        left: 4px;
        top: 4px;
      }
    }
    &-next-icon,
    &-super-next-icon {
      &::after,
      &::before {
        border-width: 0 1.5px 1.5px 0;
      }
    }
    &-prev-icon,
    &-super-prev-icon {
      &::after,
      &::before {
        border-width: 1.5px 0 0 1.5px;
      }
    }
    &-next-icon,
    &-prev-icon {
      &::after {
        display: none;
      }
    }
    &-month,
    &-year {
      &-panel {
        width: 280px;
        .ant {
          &-picker {
            &-cell {
              &-inner {
                width: initial;
              }
            }
            &-content {
              table-layout: fixed;
              width: 100%;
            }
          }
        }
      }
    }
    &-panel {
      background-color: color("light");
      border-radius: 4px;
      box-shadow: 0 3px 6px -4px color("box-shadow"),
        0 6px 16px color("box-shadow"), 0 9px 28px 8px color("box-shadow");
      overflow: hidden;
      transition: margin 0.3s;
      vertical-align: top;
    }
    &-panels {
      display: inline-flex;
    }
    &-suffix {
      display: inline-flex;
      height: 19px;
      cursor: pointer;
    }
    &-clear {
      display: inline-flex;
      height: 16px;
      margin-left: 6px;
      cursor: pointer;
    }
    &-today-btn {
      color: color("primary");
      cursor: pointer;
    }
    &-year {
      &-btn {
        margin-left: 8px;
      }
      &-panel {
        .ant-picker-decade-btn {
          pointer-events: none;
        }
      }
    }
  }
}
