@import "../../../../../style/variables/function/color";

.ant {
  &-date {
    &-time {
      &-picker {
        border: 1px solid color("form-entry-border");
        border-radius: 4px;
        display: flex;
        &-date,
        &-hour,
        &-minutes {
          margin: 0 !important;
        }
        &-date {
          max-width: 50%;
          width: 100%;
        }
        &-hour {
          max-width: 25%;
          position: relative;
          &::before {
            background-color: color("form-entry-border");
            bottom: 7px;
            content: "";
            height: 26px;
            left: 0;
            position: absolute;
            top: 7px;
            width: 1px;
            z-index: 1;
          }
        }
        &-minutes {
          max-width: 25%;
          position: relative;
          &::before {
            content: ":";
            position: absolute;
            top: 4px;
            z-index: 1;
          }
        }
        .ant {
          &-picker {
            border: none;
          }
          &-select {
            border-radius: 4px;
            &-arrow {
              display: none;
            }
            &-disabled {
              background-color: #fff;
              border: none;
            }
            &-selector {
              background-color: #fff;
              border: none;
            }
          }
          &-form-item-label {
            display: inline-block;
            height: 0;
            margin-right: 0;
            min-height: initial;
            overflow: hidden;
            text-indent: 100%;
            width: 0;
          }
        }
      }
    }
  }
}
