@import "../../variables/function/color";

.ant {
  &-dropdown {
    display: block;
    left: -9999px;
    position: absolute;
    top: -9999px;
    z-index: 1050;
    &-hidden {
      display: none;
    }
    &-menu {
      background-color: color("light");
      border-radius: 2px;
      box-shadow: 0 3px 6px -4px color("box-shadow");
      list-style: none;
      margin: 0;
      outline: none;
      position: relative;
      padding: 0;
      text-align: left;
      &:focus-visible {
        box-shadow: 0 0px 4px color("focus");
        outline-offset: 1px;
        transition: outline-offset 0s, outline 0s;
      }
      &-item {
        cursor: pointer;
        margin: 0;
        position: relative;
        transition: all 0.3s;
        &-active {
          background-color: color("selected");
        }
      }
      &-title-content {
        cursor: pointer;
        display: block;
        padding: 5px 12px;
      }
    }
    &.ant {
      &-slide {
        &-down {
          &-enter.ant-slide-down-enter-active {
            animation-name: antSlideUpIn;
          }
          &-leave.ant-slide-down-leave-active {
            animation-name: antSlideUpOut;
          }
        }
      }
    }
  }
}
