@import "../../../../style/variables/function/color";
@import "../../../../style/variables/function/font";

.ant {
  &-form {
    &-item {
      margin-top: 25px;
      margin-bottom: 25px;
      flex-grow: 1;
      &-extra {
        transform: translateY(10px);
      }
      &-hidden {
        display: none;
      }
      &-label {
        &-left {
          .form-label {
            text-align: left;
          }
        }
        > label {
          align-items: center;
          display: inline-flex;
          flex-grow: 1;
          height: 40px;
          justify-content: space-between;
          width: 100%;
        }
      }
      &-explain {
        height: 0;
        // max-width: calc(100% - 78px);
        &-connected {
          height: initial;
        }
        &-error {
          color: color("danger");
          font-size: fsize("x-small");
          transition: color 0.3s ease-in-out;
          white-space: pre-line;
          &:not(:first-child) {
            display: none;
          }
        }
      }
      &-tooltip {
        background-color: transparent;
        border: 0;
        height: 16px;
        margin-bottom: 10px;
        margin-left: 10px;
        padding: 0;
        &:focus-visible {
          box-shadow: 0px 0px 4px color("focus");
        }
        &-icon {
          width: 16px;
        }
      }
    }
    &-horizontal {
      .ant {
        &-form {
          &-item {
            margin-left: 15px;
            margin-right: 15px;
            &-extra {
              margin-left: -300px;
            }
            &-label {
              margin-right: 10px;
              width: 290px;
              display: flex;
            }
            &-control {
              max-width: calc(100% - 300px);
            }
          }
          &-btn-submit {
            .ant-form-item-control {
              max-width: 100%;
            }
          }
        }
        &-input-group {
          .ant-form-item {
            margin: 0;
            &:not(:first-child) {
              margin-left: 10px;
            }
            &-control {
              max-width: 100%;
            }
          }
        }
        &-date-time-picker {
          .ant-form-item-control {
            max-width: 100%;
          }
        }
        &-table-cell {
          .ant {
            &-form-item {
              margin-left: 0;
              margin-right: 0;
              &-control {
                max-width: 100%;
              }
              &-explain-connected {
                min-height: 25px;
              }
              &-auto-complete {
                .ant {
                  &-select {
                    &-focused {
                      transform: translateY(15px);
                      margin-bottom: 30px;
                    }
                  }
                  &-input-show-count-suffix {
                    bottom: 20px;
                  }
                }
              }
            }
            &-input {
              &-suffix {
                width: 100%;
              }
              &-affix-wrapper-focused {
                display: flex;
                flex-wrap: wrap;
                transform: translateY(15px);
                padding-bottom: 30px;
                .ant-input-suffix {
                  height: initial;
                  visibility: visible;
                  transform: translateY(0);
                  opacity: 1;
                }
              }
              // &-show-count-suffix {
              //   position: initial;
              // }
            }
          }
        }
      }
    }
    &-inline {
      .ant-form-item {
        &-label {
          width: 100%;
          > label {
            height: initial;
          }
        }
      }
    }
    &-vertical {
      .ant {
        &-form {
          &-item {
            width: 100%;
            &-control {
              width: 100%;
            }
            &-label {
              width: 100%;
              > label {
                height: initial;
                margin-bottom: 4px;
              }
            }
            &-required {
              width: 100%;
            }
          }
        }
        &-input {
          &-group {
            flex-wrap: wrap;
            .ant-form-item {
              &:first-child {
                margin-top: 0;
              }
              &:not(:first-child) {
                margin-bottom: 0;
                margin-left: 0;
              }
            }
          }
          &-wrapper {
            flex-wrap: nowrap;
          }
        }
      }
    }
  }
  &-row {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
  }
  &-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
}

@media (max-width: 768px) {
  .ant-form-vertical {
    .ant-input-show-count-suffix {
      position: initial;
    }
  }
}
