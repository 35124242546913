.btn {
  &-submit {
    position: relative;
    &-flex {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      &-column {
        flex-direction: column;
      }
      &-order {
        .btn {
          &-submit-item {
            width: 100%;
          }
          &-reset {
            order: 2;
            margin-right: 0;
            margin-top: 40px;
          }
        }
      }
    }
  }
}
