.ant {
  &-slide {
    &-up {
      &-leave,
      &-enter,
      &-appear {
        animation-duration: 0.2s;
        animation-fill-mode: both;
        animation-play-state: paused;
      }
      &-leave {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        &.ant-slide-up-leave-active {
          animation-name: antSlideUpOut;
          animation-play-state: running;
          pointer-events: none;
        }
      }
      &-enter,
      &-appear {
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0;
        &.ant {
          &-slide {
            &-up {
              &-enter-active,
              &-appear-active {
                animation-name: antSlideUpIn;
                animation-play-state: running;
              }
            }
          }
        }
      }
    }
  }
}