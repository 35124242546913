@import "../../../style/variables/function/color";

.ant-tooltip {
  width: max-content;
  &-item {
    display: inline-flex;
    align-items: center;
  }
  &-btn {
    background-color: transparent;
    border: 0;
    height: 16px;
    margin-left: 10px;
    padding: 0;
    position: relative;
    &:focus {
      box-shadow: inset 0px 0px 0px 2px color("box-shadow");
    }
    &::before {
      background-color: color("primary");
      content: "";
      display: inline-block;
      height: 10px;
      opacity: 0;
      position: absolute;
      transform: translate(2px, -30px) rotate(45deg);
      transition: visibility 0.3s ease-in-out;
      transition-delay: 0.1s;
      visibility: hidden;
      width: 10px;
      z-index: 1;
    }
  }
  &-open {
    &::before {
      visibility: visible;
      opacity: 1;
      transform: translate(2px, -20px) rotate(45deg);
    }
  }
  &-content {
    position: relative;
    padding-bottom: 10px;
  }
  &-hidden {
    display: none;
  }
  &-icon {
    cursor: pointer;
    width: 16px;
  }
  &-inner {
    background-color: color("primary");
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 color("box-shadow"),
      0 3px 6px -4px color("box-shadow"), 0 9px 28px 8px color("box-shadow");
    color: color("light");
    max-width: 300px;
    min-height: 32px;
    min-width: 32px;
    padding: 6px 8px;
    text-decoration: none;
    width: 300px;
    word-wrap: break-word;
  }
}
@keyframes cursorAnimation {
  0% {
    transform: translate(2px, -30px) rotate(45deg);
  }
  75% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translate(2px, -20px) rotate(45deg);
  }
}
