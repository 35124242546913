@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";

.list {
  border-bottom: 1px solid color("border");
  font-size: fsize("large");
  font-weight: fweight("medium");
  padding-bottom: 28px;
  margin-bottom: 30px;
  &-content {
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
  }
  &-heading {
    align-items: center;
    background-color: color("heading");
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    margin-bottom: 28px;
    min-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &-title {
    border-bottom: 1px solid color("primary");
    color: color("primary");
    font-size: fsize("x-large");
    font-weight: fweight("medium");
    margin-bottom: 4px;
    margin-top: 24px;
    padding-bottom: 4px;
  }
}
