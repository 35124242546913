@import "../../../../style/variables/function/color";
@import "../../../../style/variables/function/font";

.ant {
  &-radio {
    cursor: pointer;
    display: inline-block;
    &:hover {
      .ant-radio-inner {
        border-color: color("primary");
      }
    }
    &-checked {
      &::after {
        animation: antRadioEffect 0.36s ease-in-out;
        animation-fill-mode: both;
        border: 1px solid color("primary");
        border-radius: 50%;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 100%;
      }
      .ant-radio-inner {
        border-color: color("primary");
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &-group {
      display: inline-block;
    }
    &-inner {
      background-color: color("light");
      border: 1px solid color("border-radio");
      border-radius: 50%;
      display: block;
      height: 24px;
      margin-right: 10px;
      position: relative;
      transition: all 0.3s;
      width: 24px;
      &::after {
        background-color: color("primary");
        border-radius: 14px;
        content: "";
        display: block;
        height: 14px;
        left: 50%;
        margin-left: -7px;
        margin-top: -7px;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
        width: 14px;
      }
    }
    &-input {
      cursor: pointer;
      inset: 0;
      opacity: 0;
      position: absolute;
      z-index: 1;
      &:focus {
        + .ant-radio-inner {
          border-color: color("primary");
          box-shadow: 0 0 0 3px color("box-shadow");
        }
      }
    }
    &-wrapper {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      font-weight: fweight("medium");
      margin-right: 8px;
      position: relative;
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
    + span {
      font-size: fsize("large");
      font-weight: fweight("medium");
    }
  }
}
@keyframes antRadioEffect {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.6);
  }
}
