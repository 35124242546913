@import "../../../../style/variables/function/color";
@import "../../../../style/variables/function/font";

.expandable {
  background-color: transparent;
  border: none;
  font-family: "tkk-yu-gothic";
  font-size: fsize("large");
  outline: none;
  padding: 0;
  text-align: left;
  cursor: pointer;
  &:focus {
    box-shadow: 0px 0px 4px color("focus");
  }
}
