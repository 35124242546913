@import "../../../style/variables/function/color";

.ant {
  &-spin {
    align-items: center;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    &-dot {
      position: relative;
      display: inline-block;
      font-size: 20px;
      width: 1em;
      height: 1em;
      transform: rotate(0);
      animation: antRotate 1.2s infinite linear;
      z-index: 2;
      &-item {
        position: absolute;
        display: block;
        width: 9px;
        height: 9px;
        background-color: color("primary");
        border-radius: 100%;
        transform: scale(0.75);
        transform-origin: 50% 50%;
        opacity: 0.3;
        animation: antSpinMove 1s infinite linear alternate;
        &:nth-child(1) {
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          top: 0;
          right: 0;
          animation-delay: 0.4s;
        }
        &:nth-child(3) {
          right: 0;
          bottom: 0;
          animation-delay: 0.8s;
        }
        &:nth-child(4) {
          bottom: 0;
          left: 0;
          animation-delay: 1.2s;
        }
      }
    }
    &-fixed {
      position: fixed;
    }
    &-mask,
    &-spinning {
      &::before {
        background-color: color("light");
        bottom: 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        &-opacity {
          opacity: 0.7;
        }
      }
    }
    &-nested-loading {
      position: relative;
    }
  }
}
@keyframes antRotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
