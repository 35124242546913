@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";

.tag {
  border-radius: 4px;
  color: color("light");
  font-size: fsize("large");
  padding: 2px 6px;
  &-default {
    background-color: color("tag-default");
  }
  &-full {
    &-width {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
  &-error {
    background-color: color("tag-error");
  }
  &-success {
    background-color: color("tag-success");
  }
  &-stop {
    background-color: color("tag-stop");
  }
  &-warning {
    background-color: color("tag-warning");
  }
}
