@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";
@import "../../../style/ant/slide";
.ant {
  &-select {
    background-color: color("light");
    height: 40px;
    position: relative;
    width: 100%;
    &-arrow {
      align-items: center;
      display: flex;
      height: 12px;
      line-height: 1;
      margin-top: -6px;
      pointer-events: none;
      position: absolute;
      right: 11px;
      text-align: center;
      top: 50%;
      vertical-align: -0.125em;
    }
    &-dropdown {
      background-color: color("light");
      border-radius: 4px;
      box-shadow: 0 3px 6px -4px color("box-shadow");
      left: -9999px;
      list-style: none;
      position: absolute;
      top: -9999px;
      z-index: 1050;
      &-hidden {
        display: none;
      }
    }
    &-focused {
      box-shadow: 0px 0px 4px color("box-shadow");
    }
    &-item {
      &-option {
        cursor: pointer;
        min-height: 46px;
        padding: 10px;
        &-selected {
          font-weight: fweight("medium");
        }
        &-active {
          background-color: color("selected");
        }
        &-disabled {
          color: color("disabled");
          cursor: not-allowed;
        }
      }
    }
    &-selector {
      align-items: center;
      border-radius: 4px;
      border: 1px solid color("form-entry-border");
      display: flex;
      height: 100%;
    }
    &-selection {
      &-item {
        display: inline-block;
        overflow: hidden;
        padding: 0 11px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 30px);
      }
      &-placeholder {
        padding: 0 11px;
      }
      &-search {
        left: 11px;
        position: absolute;
        right: 32px;
        &-input {
          appearance: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-family: "tkk-yu-gothic";
          font-size: fsize("large");
          height: 38px;
          margin-top: 1px;
          outline: none;
          padding: 0;
          width: 100%;
        }
      }
    }
    &-status {
      &-error {
        .ant-select-selector {
          border-color: color("danger");
        }
      }
    }
    &-borderless {
      .ant-select {
        &-selector {
          border: none;
          font-size: 16px;
        }
        &-suffix {
          svg {
            width: 14px;
          }
        }
      }
    }
    &-disabled {
      .ant {
        &-select {
          &-arrow {
            opacity: 0.7;
          }
          &-selector {
            background-color: color("disabled");
            opacity: 0.7;
          }
        }
      }
    }
  }
}
