@import "../../../../../style/variables/function/color";
@import "../../../../../style/variables/function/font";

.ant {
  &-form {
    &-item {
      &-control {
        flex-grow: 1;
        &-input {
          &-content {
            position: relative;
            text-align: left;
          }
        }
      }
    }
  }
  &-input {
    border: 1px solid color("form-entry-border");
    border-radius: 4px;
    color: color("text");
    font-family: "tkk-yu-gothic";
    font-size: fsize("large");
    height: 40px;
    outline: none;
    padding: 0 11px;
    width: 100%;
    &:focus {
      box-shadow: 0px 0px 4px color("focus");
    }
    &:read-only {
      border: none;
      box-shadow: none;
      padding: 0;
      text-overflow: ellipsis;
      &:disabled {
        background-color: color("light");
      }
    }
    &-affix-wrapper {
      &-disabled {
        .ant {
          &-input {
            &:disabled {
              background-color: color("disabled");
              border: 1px solid color("form-entry-border");
              opacity: 0.7;
              padding-left: 11px;
              padding-right: 11px;
            }
          }
        }
      }
      &-focused {
        .ant-input-suffix {
          height: initial;
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
    &-password {
      display: block;
      position: relative;
      .ant-input {
        padding-right: 40px;
        &-suffix {
          height: initial;
          opacity: 1;
          transform: translateY(0);
          visibility: visible;
          .ant-input {
            &-password-icon {
              position: absolute;
              right: 11px;
              transform: translateY(-30px);
            }
            &-show-count-suffix {
              position: initial;
              opacity: 0;
              transform: translateY(-5px);
              transition: 0.2s transform ease-in-out;
              // visibility: hidden;
            }
          }
        }
      }
      &.ant-input-affix-wrapper {
        &-focused {
          .ant-input-show-count-suffix {
            height: initial;
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
          }
        }
        &-status-error {
          .ant-input {
            border-color: color("danger");
          }
        }
      }
    }
    &-status {
      &-error {
        border-color: color("danger");
      }
    }
    &-show {
      &-count {
        &-suffix {
          bottom: -20px;
          color: color("danger");
          font-size: fsize("x-small");
          position: absolute;
          right: 0;
        }
      }
    }
    &-suffix {
      display: block;
      height: 0;
      visibility: hidden;
      opacity: 0;
      text-align: right;
      transform: translateY(-5px);
      transition: 0.2s all ease-in-out;
      &.active {
        height: initial;
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
