@import "../../../../../style/ant/datepicker";

.ant {
  &-picker {
    &-datetime {
      &-panel {
        display: flex;
        &-active {
          .ant-picker-time-panel {
            opacity: 0.3;
            &-active {
              opacity: 1;
            }
          }
        }
      }
    }
    &-time {
      &-panel {
        border-left: 1px solid #f0f0f0;
        transition: opacity 0.3s;
        width: 170px;
        min-width: auto;
        &-column {
          flex: 1 0 auto;
          width: 56px;
          margin: 0;
          padding: 0;
          overflow-y: hidden;
          text-align: left;
          list-style: none;
          transition: background 0.3s;
          &::after {
            display: block;
            height: 196px;
            content: "";
          }
          &:not(:first-child) {
            border-left: 1px solid #f0f0f0;
          }
          &:hover {
            overflow-y: auto;
          }
          &-active {
            background: rgba(230, 247, 255, 0.2);
          }
        }
        &-cell {
          padding: 0;
          margin: 0;
          &-inner {
            display: block;
            width: 100%;
            height: 28px;
            margin: 0;
            padding: 0 0 0 14px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
            border-radius: 0;
            cursor: pointer;
            transition: background 0.3s;
            &:hover {
              background: #f5f5f5;
            }
          }
        }
        .ant-picker-content {
          display: flex;
          flex: auto;
          height: 224px;
        }
        ~ .ant-picker-footer {
          display: none;
        }
      }
    }
    &-ranges {
      margin: 0;
      padding: 4px 12px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      list-style: none;
    }
    &-now {
      cursor: pointer;
    }
    &-focused {
      box-shadow: 0px 0px 4px color("box-shadow");
    }
  }
}
