@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";

.ant {
  &-btn {
    border: 1px solid transparent;
    &-danger {
      background-color: color("danger");
      border-color: color("danger");
      color: color("light");
    }
    &-default {
      background-color: color("btn-default");
      border-color: color("btn-default-border");
      color: color("text");
    }
    &-ghost {
      background-color: color("light");
      border-color: color("border");
      color: color("text");
    }
    &-primary {
      background-color: color("btn-primary");
      border-color: color("btn-primary");
      color: color("light");
    }
    &-secondary {
      background-color: color("btn-secondary");
      border-color: color("btn-secondary");
      color: color("light");
    }
    &-warning {
      background-color: color("btn-warning");
      border-color: color("btn-warning");
      color: color("light");
    }
    &-sm {
      min-width: initial;
      padding: 0 8px;
    }
    &-disabled {
      background-color: color("disabled");
      border-color: color("disabled");
      color: color("light");
      cursor: not-allowed;
      opacity: 0.7;
      svg {
        fill: color("light");
      }
    }
  }
}
