@import "../../../style/variables/function/color";
@import "../../../style/variables/function/font";

.card {
  &-heading {
    align-items: flex-end;
    color: color("primary");
    display: flex;
    flex-wrap: wrap;
    font-size: fsize("xx-large");
    font-weight: fweight("medium");
    justify-content: space-between;
    position: relative;
    &-left {
      position: relative;
    }
    &-right {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    &-center {
      justify-content: center;
    }
    &-search {
      margin-bottom: 5px;
    }
    &-text {
      padding-left: 20px;
      position: relative;
    }
    &-bg {
      position: absolute;
      top: -26px;
      left: -10px;
    }
  }
  .ant {
    &-spin {
      border-radius: 10px;
      overflow: hidden;
      &-mask,
      &-spinning {
        &::before {
          background-color: rgba(255, 255, 255, 0.16);
        }
      }
    }
  }
}
